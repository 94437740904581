



















import { Component, Vue } from "vue-property-decorator";

@Component
export default class FacebookLogin extends Vue {
  facebookUrl: string = "";
  clientId: string = "";
  redirectUri: string = "";
  state: any = "";
  login() {
    this.state = new Date().getTime();
    localStorage.setItem("state", this.state);
    this.facebookUrl = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.state}`;
    window.open(this.facebookUrl, "_self");
  }

  created() {
    this.clientId = process.env.VUE_APP_FACEBOOK_CLIENT_ID;
    this.redirectUri = `${window.location.protocol}//${window.location.host}/facebook_auth/`;
    this.facebookUrl = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.state}`;
  }
}
