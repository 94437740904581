



















































































































































































































import { Vue, Component } from "vue-property-decorator";
import {
  CommonStore as commonstore,
  PublicModule as publicStore,
} from "@/store/modules";
import FacebookLoginButton from "@/components/UIComponents/FacebookLoginButton.vue";
import GoogleLoginButton from "@/components/UIComponents/GoogleLoginButton.vue";
import { RootRouter } from "@/utils/newRoutePathConstant";

@Component({
  components: {
    FacebookLoginButton,
    GoogleLoginButton,
  },
})
export default class Owner extends Vue {
  isCreate: boolean = true;
  status: string = "";

  get RootRouter() {
    return RootRouter;
  }

  get publicUsers() {
    return publicStore.public;
  }

  get profile() {
    return publicStore.profile;
  }

  async onRegisterClick() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    commonstore.showLoading();
    await publicStore.createPublicUser();
    this.$snotify.success(
      "Registered successful. Please check your email to activate your user."
    );
    commonstore.hideLoading();
    this.$router.push(RootRouter.PublicRegistrationLandingPage);
  }

  async created() {
    let vm = this;
    const id = vm.$route.query["id"];
    if (id) {
      vm.isCreate = false;
      this.status = "edit";
      await publicStore.getPublicUserById(id);
    } else {
      publicStore.resetField();
    }
  }

  tryLogin() {
    this.$bvModal.show("login");
  }
}
