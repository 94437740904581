







import { Component, Vue } from "vue-property-decorator";

@Component
export default class FacebookLogin extends Vue {
  googleUrl: string = "";
  clientId: string = "";
  redirectUri: string = "";
  state: any = "";
  login() {
    this.state = new Date().getTime();
    localStorage.setItem("state", this.state);
    this.googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.state}&response_type=token&scope=email profile openid`;
    window.open(this.googleUrl, "_self");
  }

  created() {
    this.clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
    this.redirectUri = `${window.location.protocol}//${window.location.host}/google_auth/?`;
    this.googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.state}&response_type=token&scope=email profile openid`;
  }
}
