var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"bg-blue section-padding color-white"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('h2',{staticClass:"color-white"},[_vm._v("Registration Form")])])],1)],1)],1),_c('section',{staticClass:"pb-5"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"md":"7","sm":"12"}},[_c('div',{staticClass:"floating-box p-md-5 p-4 box-shadow bg-white"},[_c('h3',{staticClass:"mb-4 color-theme"},[_vm._v("General User")]),_c('b-form',{staticClass:"needs-validation",attrs:{"novalidate":""}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{class:{
                      'is-error': _vm.fieldHasErrors('First Name'),
                      'is-required': _vm.isFieldRequired('First Name'),
                    },attrs:{"id":"first-name","label-for":"first-name","label-class":"form-label"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" First Name "),_c('span',{staticClass:"color-red"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",attrs:{"id":"first-name","name":"First Name","type":"text"},model:{value:(_vm.publicUsers.firstName),callback:function ($$v) {_vm.$set(_vm.publicUsers, "firstName", $$v)},expression:"publicUsers.firstName"}}),(_vm.errors.has('First Name'))?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errors.first("First Name"))+" ")]):_vm._e()],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Enter First Name ")])],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{class:{
                      'is-error': _vm.fieldHasErrors('Last Name'),
                      'is-required': _vm.isFieldRequired('Last Name'),
                    },attrs:{"id":"last-name","label-for":"last-name","label-class":"form-label"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Last Name "),_c('span',{staticClass:"color-red"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",attrs:{"id":"last-name","name":"Last Name","type":"text"},model:{value:(_vm.publicUsers.lastName),callback:function ($$v) {_vm.$set(_vm.publicUsers, "lastName", $$v)},expression:"publicUsers.lastName"}}),(_vm.errors.has('Last Name'))?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errors.first("Last Name"))+" ")]):_vm._e()],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Enter Last Name ")])],1)],1),_c('b-form-group',{staticClass:"mb-3",class:{
                  'is-error': _vm.fieldHasErrors('Email'),
                  'is-required': _vm.isFieldRequired('Email'),
                },attrs:{"id":"email","label-for":"email","label-class":"form-label"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email "),_c('span',{staticClass:"color-red"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"form-control",attrs:{"id":"email","name":"Email","type":"email"},model:{value:(_vm.publicUsers.email),callback:function ($$v) {_vm.$set(_vm.publicUsers, "email", $$v)},expression:"publicUsers.email"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please choose a email. ")]),(_vm.errors.has('Email'))?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errors.first("Email"))+" ")]):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{staticClass:"mb-3",class:{
                      'is-error': _vm.fieldHasErrors('Password'),
                      'is-required': _vm.isFieldRequired('Password'),
                    },attrs:{"id":"password","label-for":"password","label-class":"form-label"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Password "),_c('span',{staticClass:"color-red"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:"Password",staticClass:"form-control",attrs:{"id":"password","name":"Password","type":"password"},model:{value:(_vm.publicUsers.password),callback:function ($$v) {_vm.$set(_vm.publicUsers, "password", $$v)},expression:"publicUsers.password"}}),(_vm.errors.has('Password'))?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errors.first("Password"))+" ")]):_vm._e()],1),_c('div',{staticClass:"valid-feedback"},[_vm._v(" Looks good! ")])],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"6","sm":"12"}},[_c('b-form-group',{staticClass:"mb-3",class:{
                      'is-error': _vm.fieldHasErrors('Confirm Password'),
                      'is-required': _vm.isFieldRequired('Confirm Password'),
                    },attrs:{"id":"check-password","label-for":"check-password","label-class":"form-label"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Confirm Password "),_c('span',{staticClass:"color-red"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:Password'),expression:"'required|confirmed:Password'"}],staticClass:"form-control",attrs:{"id":"check-password","name":"Confirm Password","type":"password","data-vv-as":"Password"},model:{value:(_vm.publicUsers.password2),callback:function ($$v) {_vm.$set(_vm.publicUsers, "password2", $$v)},expression:"publicUsers.password2"}}),(_vm.errors.has('Confirm Password'))?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errors.first("Confirm Password"))+" ")]):_vm._e()],1),_c('div',{staticClass:"valid-feedback"},[_vm._v(" Looks good! ")])],1)],1),_c('div',{staticClass:"d-grid mb-3"},[_c('a',{staticClass:"btn btn-primary",attrs:{"type":"submit"},on:{"click":_vm.onRegisterClick}},[_vm._v(" Register "),_c('i',{staticClass:"icon-arrow-right2"})])]),_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"6","sm":"12"}},[_c('FacebookLoginButton')],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"6","sm":"12"}},[_c('GoogleLoginButton')],1)],1),_c('div',{staticClass:"text-center pt-3"},[_vm._v(" Already have a account ? "),_c('router-link',{attrs:{"to":""}},[_c('span',{on:{"click":_vm.tryLogin}},[_vm._v("Login")])])],1)],1)],1),_c('p',{staticClass:"text-center d-md-flex d-block justify-content-between"},[_c('router-link',{staticClass:"px-2",attrs:{"to":_vm.RootRouter.GpsProviderRegister}},[_c('i',{staticClass:"icon-arrow-right2"}),_vm._v("Register as GPS Service Company ")]),_c('router-link',{staticClass:"color-red",attrs:{"to":_vm.RootRouter.YatayatRegister}},[_c('i',{staticClass:"icon-arrow-right2"}),_vm._v(" Register as Public Transport Service Company")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }